import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EventFilter from './EventFilter';
import EventToolbarInfoButton from './EventToolbarInfoButton';
import Download from '../core/fiesta-icons/Download';
import Play from '../core/fiesta-icons/Play';
import VerticalEllipses from '../core/fiesta-icons/VerticalEllipses';
import Filter from '../core/fiesta-icons/Filter';
import CompanyInfo from '../core/CompanyInfo';
import { ShareList } from '../core/ShareList';
import './EventToolbar.styl';
import { getApiName } from '../../utils/utils';

const EventToolbar = ({
  onFiltersApplied,
  onSlideshowPress,
  onDownloadPress,
  onClearFilter,
  isDownloadZipDisabled,
}) => {
  const [filterString, setFilterString] = useState('Newest → Oldest');
  const [filtersOpen, setFiltersOpen] = useState(false);

  const {
    eventId,
    eventTitle,
    accentColor,
    contrastColor,
    filters,
    companySection,
    socialMediaSection,
    organization,
    loading,
    apiName,
    startDate,
    zipDownloadEnabled,
  } = useSelector((state) => {
    const event = state.activeEvent;
    return {
      eventId: event.eventId,
      eventTitle: event.title,
      accentColor: event.accentColor,
      contrastColor: event.contrastColor,
      filters: state.filters,
      companySection: event.liveGallery?.companySection || false,
      socialMediaSection: event.liveGallery?.socialMediaSection || false,
      organization: event.organization || {},
      loading: state.loading,
      apiName: event.apiName,
      startDate: event.dateMoment,
      zipDownloadEnabled: event.liveGallery.zipDownloadEnabled,
    };
  });

  const applyFilters = (filters) => {
    onFiltersApplied(filters);

    const filterOrder = filters.order === 'ASC' ? 'Oldest → Newest' : 'Newest → Oldest';
    setFilterString(filterOrder);
    setFiltersOpen(false);
  };

  const plan = organization?.plan?.toLowerCase() || 'plus';
  const filterClasses = ['event-toolbar__filters'];
  if (accentColor === '#FFFFFF') {
    filterClasses.push('gray-border');
  }

  return (
    <div
      className="lgtoolbar"
      style={{
        backgroundColor: accentColor,
        color: contrastColor,
      }}
    >
      <h2 className="lgtoolbar__event-title">{eventTitle}</h2>
      <menu className="lgtoolbar__btns">
        <EventToolbarInfoButton label="Filter" shouldDisplayLabel={true} icon={<Filter />} color={contrastColor}>
          <EventFilter
            filters={filters}
            onFilterClick={applyFilters}
            startDate={startDate}
            accentColor={accentColor}
            contrastColor={contrastColor}
            apiName={getApiName(eventId)}
            onClearFilter={onClearFilter}
          />
        </EventToolbarInfoButton>
        {zipDownloadEnabled && (
          <EventToolbarInfoButton
            isDisabled={isDownloadZipDisabled}
            label="Download captures"
            icon={<Download />}
            onPress={onDownloadPress}
            color={contrastColor}
          />
        )}
        {plan === 'pro' && (
          <EventToolbarInfoButton
            label="Play slideshow"
            icon={<Play />}
            onPress={onSlideshowPress}
            color={contrastColor}
          />
        )}
        {!!companySection && (
          <EventToolbarInfoButton label="Company info" icon={<VerticalEllipses />} color={contrastColor}>
            <CompanyInfo showDisclaimers={true} />
          </EventToolbarInfoButton>
        )}
      </menu>
    </div>
  );
};

export default EventToolbar;

import './Button.styl';

const Button = ({ variant, onClick, isDisabled, children }) => {
  const classes = ['lgbtn'];
  if (variant) {
    classes.push(`lgbtn--${variant}`);
  }
  return (
    <button onClick={onClick} className={classes.join(' ')} disabled={isDisabled}>
      <span className="lgbtn__label">{children}</span>
    </button>
  );
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const Loading = ({ loading, height, spinnerSize }) => {
  return (
    loading && (
      <div className="text-center pt-4 pb-5" style={{ height: height || 'auto' }}>
        <Spinner style={{ width: spinnerSize, height: spinnerSize }} />
      </div>
    )
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loading;

import React, { useReducer } from 'react';
import Datetime from 'react-datetime';
import { isMobile } from '../../utils/utils';
import './EventFilter.styl';
import Button from './Button';

/**
 * Initializes the state with the provided filters.
 * @param {Object} filters - The initial filter settings.
 * @returns {Object} The initial state.
 */
const initialState = () => ({
  dateFrom: null,
  dateTo: null,
  order: 'DESC',
  captureMode: new Set([]),
});

/**
 * Reducer function to manage the state of the EventFilter component.
 * @param {Object} state - The current state.
 * @param {Object} action - The action to be performed.
 * @returns {Object} The new state after the action is applied.
 */
function reducer(state, action) {
  switch (action.type) {
    case 'SET_ORDER':
      return { ...state, order: action.value };
    case 'TOGGLE_CAPTURE_MODE': {
      return { ...state, captureMode: new Set([action.value]) };
      /**
       * @todo Support more than one capture mode at a time.
       */
      /* const newcaptureMode = new Set(state.captureMode);
      if (newcaptureMode.has(action.value)) {
        newcaptureMode.delete(action.value);
      } else {
        newcaptureMode.add(action.value);
      }
      return { ...state, captureMode: newcaptureMode }; */
    }
    case 'SET_DATE_FROM':
      return { ...state, dateFrom: action.value };
    case 'SET_DATE_TO':
      return { ...state, dateTo: action.value };
    case 'RESET_FILTERS':
      return initialState();
    default:
      return state;
  }
}

/**
 * EventFilter component for filtering events by various criteria.
 * @param {Object} props - The props for the component.
 * @param {Object} props.filters - The initial filters to apply.
 * @param {Function} props.onFilterClick - The callback function to apply the filters.
 * @param {boolean} props.loading - Indicates whether filters are being applied.
 * @param {string} props.accentColor - The accent color for the Apply button.
 * @param {string} props.contrastColor - The contrast color for the Apply button text.
 * @param {string} props.startDate - The start date for date range filtering.
 * @param {string} [props.apiName] - The name of the API, determines additional filter options.
 * @returns {JSX.Element} The rendered EventFilter component.
 */
const EventFilter = (props) => {
  const [state, dispatch] = useReducer(reducer, props.filters, initialState);

  const isMobileDevice = isMobile();

  /**
   * Handles the application of filters when the Apply button is clicked.
   */
  const onFilterClick = () => {
    props.onFilterClick(state);
  };

  /**
   * Handles resetting all filters to their initial state.
   */
  const onClearAll = () => {
    dispatch({ type: 'RESET_FILTERS', filters: props.filters });
    props.onFilterClick(initialState());
    props.onClearFilter();
  };

  /**
   * Validates the 'From Date' input to ensure it's within the allowed range.
   * @param {Object} current - The current date being validated.
   * @returns {boolean} Whether the date is valid.
   */
  const isValidFromDate = (current) => {
    const today = Datetime.moment();
    const startDate = Datetime.moment(props.startDate).subtract(1, 'days');
    if (state.dateTo) {
      return current.isSameOrBefore(state.dateTo) && current.isSameOrAfter(startDate);
    }
    return current.isSameOrBefore(today) && current.isSameOrAfter(startDate);
  };

  /**
   * Validates the 'To Date' input to ensure it's within the allowed range.
   * @param {Object} current - The current date being validated.
   * @returns {boolean} Whether the date is valid.
   */
  const isValidToDate = (current) => {
    const today = Datetime.moment();
    if (state.dateFrom) {
      return current.isSameOrAfter(state.dateFrom) && current.isSameOrBefore(today);
    }
    return current.isSameOrBefore(today) && current.isSameOrAfter(props.startDate);
  };

  const disabled = props.loading ? 'disabled' : '';
  const btnClasses = ['btn'];
  if (props.accentColor === '#FFFFFF') {
    btnClasses.push('gray-border');
  }

  return (
    <div className="lgfilters">
      <section className="lgfilters__section">
        <h2 className="lgfilters__heading">Sort</h2>
        <div className="lgfilters__radios">
          <input
            type="radio"
            name="order"
            value="DESC"
            checked={state.order === 'DESC'}
            onChange={(e) => dispatch({ type: 'SET_ORDER', value: e.currentTarget.value })}
            id="descInput"
          />
          <label htmlFor="descInput">Newest &rarr; Oldest</label>

          <input
            type="radio"
            name="order"
            value="ASC"
            checked={state.order === 'ASC'}
            onChange={(e) => dispatch({ type: 'SET_ORDER', value: e.currentTarget.value })}
            id="ascInput"
          />
          <label htmlFor="ascInput">Oldest &rarr; Newest</label>
        </div>
      </section>

      <section className="lgfilters__section">
        <h2 className="lgfilters__heading">Filter</h2>

        <div className="lgfilters__checkboxes">
          <input
            type="checkbox"
            name="captureMode"
            value="Photo"
            checked={state.captureMode.has('Photo')}
            onChange={(e) => dispatch({ type: 'TOGGLE_CAPTURE_MODE', value: e.currentTarget.value })}
            id="photoCheckbox"
          />
          <label htmlFor="photoCheckbox">Photo</label>

          <input
            type="checkbox"
            name="captureMode"
            value="Video"
            checked={state.captureMode.has('Video')}
            onChange={(e) => dispatch({ type: 'TOGGLE_CAPTURE_MODE', value: e.currentTarget.value })}
            id="videoCheckbox"
          />
          <label htmlFor="videoCheckbox">Video</label>

          {props.apiName === 'salsa' && (
            <>
              <input
                type="checkbox"
                name="captureMode"
                value="Boomerang"
                checked={state.captureMode.has('Boomerang')}
                onChange={(e) => dispatch({ type: 'TOGGLE_CAPTURE_MODE', value: e.currentTarget.value })}
                id="boomerangCheckbox"
              />
              <label htmlFor="boomerangCheckbox">Boomerang</label>

              <input
                type="checkbox"
                name="captureMode"
                value="GIF"
                checked={state.captureMode.has('GIF')}
                onChange={(e) => dispatch({ type: 'TOGGLE_CAPTURE_MODE', value: e.currentTarget.value })}
                id="gifCheckbox"
              />
              <label htmlFor="gifCheckbox">GIF</label>
            </>
          )}
        </div>
      </section>

      <section className="lgfilters__section">
        <h2 className="lgfilters__heading">Date</h2>

        <div className="lgfilters__input">
          <label className="visuallyhidden" htmlFor="dateFromInput">
            From date
          </label>
          <Datetime
            id="dateFromInput"
            isValidDate={isValidFromDate}
            value={state.dateFrom}
            viewDate={Datetime.moment(props.startDate)}
            onChange={(current) => dispatch({ type: 'SET_DATE_FROM', value: current })}
            inputProps={{ readOnly: isMobileDevice, placeholder: 'From date' }}
            timeFormat={false}
          />
        </div>

        <div className="lgfilters__input">
          <label className="visuallyhidden" htmlFor="dateToInput">
            To date
          </label>
          <Datetime
            id="dateToInput"
            isValidDate={isValidToDate}
            value={state.dateTo}
            viewDate={Datetime.moment(props.startDate)}
            onChange={(current) => dispatch({ type: 'SET_DATE_TO', value: current })}
            inputProps={{ readOnly: isMobileDevice, placeholder: 'To date' }}
            timeFormat={false}
          />
        </div>
      </section>

      <div className="lgfilters__apply">
        <Button variant="primary" isDisabled={disabled} onClick={onFilterClick}>
          Apply
        </Button>
        <Button variant="secondary" isDisabled={disabled} onClick={onClearAll}>
          Clear All
        </Button>
      </div>
    </div>
  );
};

export default EventFilter;

import React from 'react';
import { connect } from 'react-redux';
import Head from 'next/head';
import { getFavIcon } from '../src/scripts/utils/getFavIcon';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/index.styl';

import Event from '../src/scripts/containers/event/event';
import { requestEvent, requestMedias } from '../src/scripts/actions/actions';
import Header from '../src/scripts/components/event/Header';
import utils from '../src/scripts/utils/utils';
import PageSpinner from '../src/scripts/components/PageSpinner';

const { liveGalleryURL } = require('../src/scripts/constants').get();

class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  static async getInitialProps({ req, store, isServer, query }) {
    const { eventId } = query;
    const isSupportedEventType = utils.getApiName(eventId) !== null;
    const beta = query.betaFeatures === 'true';

    if (isServer) {
      const domain = req.headers.host;
      let subdomain;
      if (domain) {
        subdomain = domain.split('.')[0];
      }

      if (isSupportedEventType) {
        store.dispatch(requestEvent(eventId, isServer, subdomain));
      }
    }

    return { isSupportedEventType, eventId, isServer, beta };
  }

  componentDidMount() {
    const { isSupportedEventType, fullGalleryEnabled, licenseCount } = this.props;
    if (!isSupportedEventType || !fullGalleryEnabled || licenseCount < 1) {
      window.location = liveGalleryURL;
    }
    this.setState({ loading: true });
    document.documentElement.classList.remove('preload');
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading && !this.props.loadingMedias && this.props.eventLoaded) {
      this.setState({ loading: false });
    }
  }

  render() {
    const screen = this.props.beta ? 'screen-beta' : 'screen';
    const {
      isServer,
      pageType,
      contactEnabled,
      contactOwner,
      fullGalleryEnabled,
      accentColor,
      contrastColor,
      eventId,
      eventTitle,
      subtitle,
      isSupportedEventType,
      background,
      backgroundColor,
      beta,
      passwordProtected,
      theme,
    } = this.props;

    if (!isSupportedEventType) {
      // will reroute in componentDidMount
      return null;
    }

    const { loading } = this.state;
    const loadingClass = loading ? 'screen-loading' : 'screen-loaded';

    return (
      <div className={[screen, loadingClass].join(' ')}>
        <Head>
          <title>{eventTitle}</title>
          {getFavIcon('blank')}
        </Head>
        {fullGalleryEnabled && (
          <div>
            <Header beta={beta} pageType={pageType} eventId={eventId} theme={theme} subtitle={subtitle} />
            <Event eventId={eventId} beta={beta} isServer={isServer} eventTitle={eventTitle} />
          </div>
        )}
        {loading && <PageSpinner backgroundColor={accentColor} color={contrastColor} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;
  return {
    pageType: 'event',
    fontColor: event.fontColor,
    backgroundColor: event.backgroundColor,
    contactEnabled: event.contactEnabled,
    contactOwner: event.contactOwner,
    fullGalleryEnabled: event.fullGalleryEnabled,
    passwordProtected: event.id ? false : event.passwordProtected,
    loadingMedias: state.loadingMedias,
    accentColor: event.accentColor,
    contrastColor: event.contrastColor,
    theme: event.liveGallery ? event.liveGallery.theme : 'light',
    leadSection: event.liveGallery?.leadSection,
    eventTitle: event.title,
    subtitle: event.subtitle,
    eventLoaded: !!state.events[ownProps.eventId],
    licenseCount: event.organization ? event.organization.licenseCount : 0,
  };
};

export default connect(mapStateToProps)(EventPage);

import React, { useState, useRef, useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import DownArrow from '../core/fiesta-icons/DownArrow';

const DROPDOWN_WIDTH = 240;

function listenForOutsideClicks(isListening, setIsListening, ref, setIsOpen) {
  return () => {
    if (isListening) {
      return;
    }
    if (!ref?.current) {
      return;
    }
    setIsListening(true);
    ['click', 'touchstart'].forEach((type) => {
      document.addEventListener('click', (evt) => {
        if (ref.current?.contains(evt.target)) {
          return;
        }
        setIsOpen(false);
      });
    });
  };
}

const EventToolbarInfoButton = ({
  label,
  icon,
  color = '#ffffff',
  shouldDisplayLabel = false,
  onPress,
  children,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [offset, setOffset] = useState({ top: 0, right: 0 });
  const parentRef = useRef(null);
  const menuRef = useRef(null);
  const { width } = useWindowSize();
  const isLabelVisible = shouldDisplayLabel && width >= 768;

  const showMenu = (e) => {
    e.preventDefault();
    const rect = parentRef.current.getBoundingClientRect();
    const leftDiff = rect.right - DROPDOWN_WIDTH;
    if (leftDiff < 0) {
      setOffset({ top: 0, right: leftDiff });
    }
    setIsOpen(true);
  };

  useEffect(listenForOutsideClicks(isListening, setIsListening, parentRef, setIsOpen));

  const renderIcon = () => {
    if (isLabelVisible) {
      return <DownArrow />;
    }
    return icon;
  };

  const btnStyles = { color, stroke: color, borderColor: isLabelVisible && color };

  if (children) {
    return (
      <li className="lgtoolbar__dropdown lgdropdown" ref={parentRef}>
        <button
          className={['lgtoolbar__btn', isLabelVisible && 'lgtoolbar__btn--with-label'].filter(Boolean).join(' ')}
          onClick={showMenu}
          title={label}
          aria-haspopup="true"
          aria-expanded={isOpen}
          style={btnStyles}
        >
          <span className={!isLabelVisible && 'sr-only'}>{label}</span>
          {renderIcon()}
        </button>

        <div
          className={['lgdropdown__menu', !isOpen && 'visuallyhidden'].filter(Boolean).join(' ')}
          style={offset}
          ref={menuRef}
        >
          {children}
        </div>
      </li>
    );
  }

  return (
    <li>
      <button
        disabled={isDisabled}
        className={['lgtoolbar__btn', isLabelVisible && 'lgtoolbar__btn--with-label'].join(' ')}
        onClick={onPress}
        title={label}
        style={btnStyles}
      >
        <span className={!isLabelVisible && 'sr-only'}>{label}</span>
        {renderIcon()}
      </button>
    </li>
  );
};

export default EventToolbarInfoButton;

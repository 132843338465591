import { connect } from 'react-redux';
import util from 'util';
import { getApiName } from '../../utils/utils';

import Event from '../../components/Event';

const getHasMore = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId].hasMore;
  }
  return false;
};

const isLoaded = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return true;
  }
  return false;
};

const isFetching = (state) => {
  if (state.isFetching.count) {
    return true;
  }
  return false;
};

const getFullGallery = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId].fullGalleryEnabled;
  }
  return true;
};

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;
  const { eventId } = event;

  return {
    id: event.id,
    eventId: ownProps.eventId,
    date: event.dateMoment,
    title: event.title,
    passwordToken: state.passwordToken,
    photos: state.photos,
    photoIndex: state.events[eventId]?.eventMedias?.map((m) => m.mediaId) ?? [],
    background: event.background,
    accentColor: event.accentColor,
    contrastColor: event.contrastColor,
    subtitle: event.subtitle,
    buttonFlag: event.buttonFlag,
    buttonLink: event.buttonLink,
    buttonText: event.buttonText,
    logo: event.logo,
    logoLink: event.logoLink,
    loading: isFetching(state),
    loadingEvent: state.loadingEvent,
    hasMore: getHasMore(state, ownProps.eventId),
    eventLoaded: isLoaded(state, ownProps.eventId),
    fullGalleryEnabled: event.fullGalleryEnabled,
    pageType: 'event',
    eventNotFound: state.eventNotFound,
    loadingMedias: state.loadingMedias,
    loadingMedia: state.loadingMedia,
    requiredPassword: state.requiredPassword,
    passwordProtected: state.activeEvent.id ? false : event.passwordProtected,
    activeMedia: state.activeMedia,
    filters: state.filters,
    sharePhoto: state.sharePhoto,
    apiName: getApiName(ownProps.eventId),
    leadSection: event.liveGallery ? event.liveGallery.leadSection : false,
    socialMediaSection: event.liveGallery ? event.liveGallery.socialMediaSection : false,
    companySection: event.liveGallery ? event.liveGallery.companySection : false,
    organization: event.organization ? event.organization : {},
    captureKinds: event.liveGallery ? event.liveGallery.displayCaptureKinds : [],
    emptyStateText: event.liveGallery.emptyStateText,
    emptyStateImageUrl: event.liveGallery.emptyStateImageUrl,
    currentPlan: event.organization.plan,
  };
};
const EventConnect = connect(mapStateToProps)(Event);

export default EventConnect;
